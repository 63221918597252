import React, { FC } from 'react';

import { useAuth } from '@lib/auth/AuthContext';
import { LinkTarget } from '@lib/slice-machine/types';
import { useTenantProps } from '@lib/tenants/TenantPropsContext';
import { isProtectedLevel } from '@templates/common/layout/page-layout/common/helpers';

import { ListItem } from '../../../../common/ListItem';
import { FullWidthItem } from '../common/FullWidthItem';

interface Props {
  title: string;
  href?: string;
  target?: LinkTarget;
  isAuthOnly?: boolean;
  isGuestOnly?: boolean;
  onClick?: () => void;
}

export const ThirdLevelMenuItem: FC<Props> = (props) => {
  const {
    href,
    target,
    title,
    isAuthOnly = false,
    isGuestOnly = false,
    onClick,
  } = props;
  const { isAuthenticated } = useAuth();
  const { tenant } = useTenantProps();
  const activeColor = tenant.theme?.specifics?.drawer?.activeColor;

  if (isProtectedLevel(isAuthenticated, isAuthOnly, isGuestOnly)) {
    return null;
  }

  return (
    <FullWidthItem href={href} target={target} onClick={onClick}>
      <ListItem title={title} activeColor={activeColor} />
    </FullWidthItem>
  );
};
