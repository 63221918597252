import { FC } from 'react';

import { useTenantProps } from '@lib/tenants/TenantPropsContext';
import { SxStyles } from '@lib/theme/sxTheme';
import { ListItem } from '@templates/common/layout/page-layout/common/ListItem';

interface Props {
  title?: string;
}

export const CategoryListItem: FC<Props> = ({ title }) => {
  const { tenant } = useTenantProps();
  const activeColor = tenant.theme?.specifics?.drawer?.activeColor;

  return (
    <ListItem
      sx={styles.getValue('root')}
      title={title}
      activeColor={activeColor}
    />
  );
};

const styles = new SxStyles({
  root: {
    fontWeight: 'fontWeightMedium',
    fontSize: 17,
  },
});
