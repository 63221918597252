import React, { FC } from 'react';

import { useAuth } from '@lib/auth/AuthContext';
import { ChildrenRenderer } from '@lib/renderers/ChildrenRenderer';
import { IterableChildren } from '@lib/renderers/types';
import { LinkTarget } from '@lib/slice-machine/types';
import { SxStyles } from '@lib/theme/sxTheme';
import { MenuList, Stack } from '@mui/material';
import { isProtectedLevel } from '@templates/common/layout/page-layout/common/helpers';

import { FullWidthItem } from '../common/FullWidthItem';
import { CategoryListItem } from './CategoryListItem';

interface Props {
  title: string;
  children: IterableChildren;
  href?: string;
  target?: LinkTarget;
  isAuthOnly?: boolean;
  isGuestOnly?: boolean;
  onClick?: () => void;
}

export const SecondLevelMenuItem: FC<Props> = (props) => {
  const {
    href,
    children,
    target,
    title,
    isAuthOnly = false,
    isGuestOnly = false,
    onClick,
  } = props;
  const { isAuthenticated } = useAuth();

  if (isProtectedLevel(isAuthenticated, isAuthOnly, isGuestOnly)) {
    return null;
  }

  return (
    <MenuList sx={styles.getValue('menuList')}>
      <FullWidthItem href={href} target={target} onClick={onClick}>
        <CategoryListItem title={title} />
      </FullWidthItem>
      <ChildrenRenderer Wrapper={Stack}>{children}</ChildrenRenderer>
    </MenuList>
  );
};

const styles = new SxStyles({
  menuList: {
    p: 0,
  },
});
