import { FC } from 'react';
import { SxStyles } from '@lib/theme/sxTheme';
import { Box, BoxProps } from '@mui/material';

export interface StaticImageProps {
  src: string;
  alt: string;
  height?: number | undefined;
  width?: number | undefined;
  containerProps?: BoxProps;
  objectFit?: 'fill' | 'contain' | 'cover' | 'none' | 'scale-down';
}

export const StaticImage: FC<StaticImageProps> = ({
  src,
  width,
  height,
  alt = '',
  containerProps = {},
  objectFit = 'cover',
}) => {
  const { sx, ...restContainerProps } = containerProps;

  return (
    <Box
      width={width}
      height={height}
      sx={{ ...styles.getValue('image'), ...sx }}
      {...restContainerProps}
    >
      <picture>
        <img
          src={src}
          alt={alt}
          width={width}
          height={height}
          style={{ objectFit }}
        />
      </picture>
    </Box>
  );
};

const styles = new SxStyles({
  image: {
    position: 'relative',
    height: '100%',
    width: '100%',
  },
});
