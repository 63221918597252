import React, { FC } from 'react';

import { useDrawer } from '@lib/DrawerContext';
import { useToggle } from '@lib/useToggle';
import {
  MenuItemStructure,
  MenuLevel,
} from '@templates/common/layout/page-layout/types';

import { FirstLevelMenuItem } from './first-level-menu-item/FirstLevelMenuItem';
import { SecondLevelMenuItem } from './second-level-menu-item/SecondLevelMenuItem';
import { ThirdLevelMenuItem } from './third-level-menu-item/ThirdLevelMenuItem';

type Props = MenuItemStructure;

export const DrawerMenuItem: FC<Props> = (props) => {
  const { levels, ...root } = props;
  const { isToggled, toggle } = useToggle();
  const { hideDrawer } = useDrawer();
  const hasLevels = !!levels?.length;

  return (
    <FirstLevelMenuItem
      isOpen={isToggled}
      hasLevels={hasLevels}
      onDropdownToggle={toggle}
      {...root}
      onClick={() => closeDrawerAfter(root.onClick)}
    >
      {levels?.map(({ parent, children }: MenuLevel, i) => (
        <SecondLevelMenuItem
          key={i}
          {...parent}
          onClick={() => closeDrawerAfter(parent.onClick)}
        >
          {children?.map((child, j) => (
            <ThirdLevelMenuItem
              key={j}
              {...child}
              onClick={() => closeDrawerAfter(child.onClick)}
            />
          ))}
        </SecondLevelMenuItem>
      ))}
    </FirstLevelMenuItem>
  );

  function closeDrawerAfter(handleClose?: Props['onClick']): void {
    if (handleClose) handleClose();
    hideDrawer();
  }
};
