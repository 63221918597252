import React, { FC } from 'react';

import { useAuth } from '@lib/auth/AuthContext';
import { ChildrenRenderer } from '@lib/renderers/ChildrenRenderer';
import { IterableChildren } from '@lib/renderers/types';
import { LinkTarget } from '@lib/slice-machine/types';
import { useTenantProps } from '@lib/tenants/TenantPropsContext';
import { SxStyles } from '@lib/theme/sxTheme';
import { Stack } from '@mui/material';
import { Box } from '@mui/system';
import { isProtectedLevel } from '@templates/common/layout/page-layout/common/helpers';
import { MenuItem } from '@templates/common/layout/page-layout/common/MenuItem';

interface Props {
  title: string;
  children: IterableChildren;
  hasLevels?: boolean;
  isOpen?: boolean;
  onDropdownToggle?: () => void;
  href?: string;
  target?: LinkTarget;
  isAuthOnly?: boolean;
  isGuestOnly?: boolean;
  onClick?: () => void;
}

export const FirstLevelMenuItem: FC<Props> = (props) => {
  const {
    isOpen,
    children,
    hasLevels = false,
    onDropdownToggle,
    title,
    href,
    target,
    isAuthOnly = false,
    isGuestOnly = false,
    onClick,
  } = props;
  const { tenant } = useTenantProps();
  const dividerColor = tenant.theme?.specifics?.drawer?.color;
  const { isAuthenticated } = useAuth();
  const dynamicStyles = styles({ dividerColor });
  const activeColor = tenant.theme?.specifics?.drawer?.activeColor;

  if (isProtectedLevel(isAuthenticated, isAuthOnly, isGuestOnly)) {
    return null;
  }

  return (
    <Box alignItems="center" width="100%" px={5} py={0}>
      <MenuItem
        isOpen={isOpen}
        hasLevels={hasLevels}
        onDropdownToggle={onDropdownToggle}
        title={title}
        href={href}
        target={target}
        onClick={onClick}
        activeColor={activeColor}
      />
      <Box sx={dynamicStyles.getValue('submenuContainer')}>
        {isOpen && (
          <ChildrenRenderer Wrapper={Stack}>{children}</ChildrenRenderer>
        )}
      </Box>
    </Box>
  );
};

interface StyleProps {
  dividerColor?: string;
}

const styles = ({ dividerColor }: StyleProps) =>
  new SxStyles({
    submenuContainer: {
      margin: (theme) => theme.spacing(1, 0),
      borderLeft: (theme) =>
        `1px solid ${dividerColor ?? theme.palette.text.primary}`,
    },
  });
