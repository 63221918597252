import { FC, useMemo } from 'react';
import Cookies from 'js-cookie';

import { COOKIE_STORAGE } from '@lib/storage/constants';
import { useTenantProps } from '@lib/tenants/TenantPropsContext';
import { Stack } from '@mui/material';

import { mapToMenuItems } from '../../common/helpers';
import { MenuItemStructure } from '../../types';
import { DrawerMenuItem } from './drawer-menu-item/DrawerMenuItem';

export const DrawerMenu: FC = () => {
  const { navigationMenu } = useTenantProps();
  const userRole = useMemo(() => Cookies.get(COOKIE_STORAGE.ROLE) ?? '', []);
  const cmsMenuItems = useMemo(
    (): MenuItemStructure[] =>
      mapToMenuItems(navigationMenu, 'drawer_menu_item_order').filter(
        (navigationItem) => navigationItem.disabledForUserRole !== userRole
      ),
    [navigationMenu, userRole]
  );
  return (
    <Stack spacing={2} overflow="auto">
      {cmsMenuItems.map((item, index) => (
        <DrawerMenuItem key={index} {...item} />
      ))}
    </Stack>
  );
};
