import { FC, PropsWithChildren } from 'react';
import { useRouter } from 'next/router';

import { isRouteActive } from '@lib/route/checkRoute';
import { LinkTarget } from '@lib/slice-machine/types';
import { useTenantProps } from '@lib/tenants/TenantPropsContext';
import { SxStyles } from '@lib/theme/sxTheme';
import { Box, MenuItem as MaterialMenuItem } from '@mui/material';
import { TextLink } from '@ui/buttons/TextLink';

interface Props {
  href?: string;
  target?: LinkTarget;
  onClick?: () => void;
}

export const FullWidthItem: FC<PropsWithChildren<Props>> = ({
  children,
  href,
  target,
  onClick,
}) => {
  const { tenant } = useTenantProps();
  const { pathname } = useRouter();
  const isActive = isRouteActive(pathname, href);
  const activeColor = tenant.theme?.specifics?.drawer?.activeColor;
  const dynamicStyles = styles({ activeColor });
  const wrapper = (content) =>
    href ? (
      <TextLink underline="none" color="inherit" href={href} target={target}>
        {content}
      </TextLink>
    ) : (
      content
    );

  return wrapper(
    <MaterialMenuItem sx={dynamicStyles.getValue('menuItem')} onClick={onClick}>
      <Box sx={dynamicStyles.merge('menuItemContainer', isActive && 'active')}>
        {children}
      </Box>
    </MaterialMenuItem>
  );
};

interface StyleProps {
  activeColor?: string;
}

const styles = ({ activeColor }: StyleProps) =>
  new SxStyles({
    menuItem: {
      display: 'flex',
      minHeight: 'initial',
      justifyContent: 'center',
    },
    menuItemContainer: {
      display: 'flex',
      width: '100%',
      alignItems: 'center',
      fontWeight: 'fontWeightMedium',
    },
    active: {
      color: activeColor ?? 'primary.main',

      '& .MuiSvgIcon-root': {
        color: activeColor ?? 'primary.main',
      },
    },
  });
