import { FC } from 'react';
import { useTranslation } from 'next-i18next';

import { useDrawer } from '@lib/DrawerContext';
import { useTenantProps } from '@lib/tenants/TenantPropsContext';
import { Tenant } from '@lib/tenants/types';
import { SxStyles } from '@lib/theme/sxTheme';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton, Stack } from '@mui/material';
import { Box } from '@mui/system';

import { TenantLogo } from '../TenantLogo';

export const DrawerHeader: FC = () => {
  const { t } = useTranslation();
  const { hideDrawer } = useDrawer();
  const { tenant } = useTenantProps();
  const dynamicStyles = styles({ specifics: tenant.theme?.specifics });

  return (
    <Stack
      spacing={1}
      justifyContent="center"
      alignItems="center"
      flexShrink={0}
      padding={1}
    >
      <Box display="flex" alignSelf="flex-end">
        <IconButton
          sx={dynamicStyles.getValue('closeBtn')}
          onClick={hideDrawer}
          aria-label={t('common:ariaLabel.close')}
        >
          <CloseIcon />
        </IconButton>
      </Box>
      <Box pb={3}>
        <TenantLogo />
      </Box>
    </Stack>
  );
};

interface StyleProps {
  specifics: Tenant['theme']['specifics'];
}

const styles = ({ specifics }: StyleProps) =>
  new SxStyles({
    closeBtn: {
      color: specifics?.drawer?.color ?? 'initial',
    },
  });
